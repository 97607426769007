import React from 'react';

import Link from 'next/link';

export function IndexPage() {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Link className="button button-primary button-filled" href="/accounts/login">Login</Link>
        </div>
    )
}

export default IndexPage;